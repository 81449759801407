import { useEffect, useState } from "react";
import styles from "./UserManagement.module.css";
import { useAdmin } from "hooks/useAdmin";
import {
  BsThreeDotsVertical,
  BsExclamationTriangle,
  BsSearch,
  BsFilter,
} from "react-icons/bs";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import UserActionModal from "../components/UserActionModal";
import HardDeleteConfirmModal from "../components/HardDeleteConfirmModal";

import { useNavigate } from "react-router-dom";

const REGISTRATION_STATUS_MAP = {
  pending: "pending",
  registered: "registered",
  emailVerified: "email verified",
  adminApproved: "admin approved",
};

// Update these constants to match the actual values in the User model
const STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "active", label: "Active" },
  { value: "suspended", label: "Suspended" },
  { value: "banned", label: "Banned" },
  { value: "deleted", label: "Deleted" },
];

const ROLE_OPTIONS = [
  { value: "", label: "All" },
  { value: "user", label: "User" },
  { value: "admin", label: "Admin" },
];

const REGISTRATION_STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "registered", label: "Registered" },
  { value: "emailVerified", label: "Email Verified" },
  { value: "adminApproved", label: "Admin Approved" },
];

const SUBSCRIPTION_OPTIONS = [
  { value: "", label: "All" },
  { value: "free", label: "Free" },
  { value: "premium", label: "Premium" },
];

const UserManagement = () => {
  const { addToast } = useToastContext();
  const navigate = useNavigate();

  const {
    users,
    fetchAllUsers,
    approveUserAccount,
    deleteUserAccount,
    banUserAccount,
    suspendUserAccount,
    restoreUserAccount,
    fetchUserDetail,
    pagination,
  } = useAdmin();

  const [selectedUser, setSelectedUser] = useState(null);
  const [isAdminActionModalOpen, setIsAdminActionModalOpen] = useState(false);
  const [adminActionType, setAdminActionType] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filtering and sorting states
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(1);

  // Add registration status and subscription filters
  const [registrationStatusFilter, setRegistrationStatusFilter] = useState("");
  const [subscriptionFilter, setSubscriptionFilter] = useState("");

  // Add state for hard delete modal
  const [isHardDeleteModalOpen, setIsHardDeleteModalOpen] = useState(false);

  const handleAdminAction = (type, user) => {
    setSelectedUser(user);
    setAdminActionType(type);
    setIsAdminActionModalOpen(true);
  };

  const getUserActionOptions = (user) => {
    const actions = [];

    // Always add View Details as the first option
    actions.push({
      label: "View Details",
      onClick: () => handleRowClick(user._id),
      color: "default",
    });

    // Only show Approve if user is not already admin approved
    if (user.registrationStatus !== "adminApproved") {
      const isUserActive = user.status === "active";
      actions.push({
        label: "Approve",
        onClick: () => handleAdminAction("Approve", user),
        color: "success",
        disabled: !isUserActive,
      });
    }

    // For active users, show suspend, ban, and delete options
    if (user.status === "active") {
      actions.push({
        label: "Suspend",
        onClick: () => handleAdminAction("Suspend", user),
        color: "secondary",
      });

      actions.push({
        label: "Ban",
        onClick: () => handleAdminAction("Ban", user),
        color: "secondary",
      });

      actions.push({
        label: "Permanently Delete",
        onClick: () => {
          setSelectedUser(user);
          setIsHardDeleteModalOpen(true);
        },
        color: "error",
      });
    }

    // For suspended/banned users, show restore option
    if (user.status === "suspended" || user.status === "banned") {
      actions.push({
        label: "Restore",
        onClick: () => handleAdminAction("Restore", user),
        color: "success",
      });
    }

    // For deleted users, show only hard delete option
    if (user.status === "deleted") {
      actions.push({
        label: "Permanently Erase User",
        onClick: () => {
          setSelectedUser(user);
          setIsHardDeleteModalOpen(true);
        },
        color: "error",
      });
    }

    return actions;
  };

  const handleCloseModal = () => {
    setSelectedUser(null);
    setIsAdminActionModalOpen(false);
    setAdminActionType(null);
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      let res;
      switch (adminActionType) {
        case "Approve":
          res = await approveUserAccount(data);
          break;
        case "Ban":
          res = await banUserAccount(data);
          break;
        case "Suspend":
          res = await suspendUserAccount(data);
          break;
        case "Delete":
          res = await deleteUserAccount(data);
          break;
        case "Restore":
          res = await restoreUserAccount(data);
          break;
        default:
          throw new Error("Invalid action type");
      }
      addToast(res.message, "success");
      loadUsers();
    } catch (err) {
      addToast(
        `Failed to ${adminActionType.toLowerCase()} user. Please try again later.`,
        "error"
      );
    } finally {
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  const handleHardDelete = async (data) => {
    setIsSubmitting(true);
    try {
      const result = await deleteUserAccount(data);
      addToast(result.message || "User permanently deleted", "success");
      loadUsers(); // Refresh user list
    } catch (err) {
      addToast(`Failed to delete user: ${err.message}`, "error");
    } finally {
      setIsSubmitting(false);
      setIsHardDeleteModalOpen(false);
    }
  };

  const loadUsers = async () => {
    setIsLoading(true);
    setError(null);

    try {
      await fetchAllUsers({
        page,
        limit: 50,
        sort: `${sortDirection === "desc" ? "-" : ""}${sortField}`,
        status: statusFilter || undefined,
        role: roleFilter || undefined,
        registrationStatus: registrationStatusFilter || undefined,
        subscription: subscriptionFilter || undefined,
        search: searchTerm || undefined,
      });
    } catch (error) {
      console.error("Error loading users:", error);
      setError("Failed to load users. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [
    page,
    sortField,
    sortDirection,
    statusFilter,
    roleFilter,
    registrationStatusFilter,
    subscriptionFilter,
  ]);

  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page when searching
    loadUsers();
  };

  const toggleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("desc"); // Default to descending for new field
    }
  };

  const clearFilters = () => {
    setSearchTerm("");
    setStatusFilter("");
    setRoleFilter("");
    setRegistrationStatusFilter("");
    setSubscriptionFilter("");
    setSortField("createdAt");
    setSortDirection("desc");
    setPage(1);
  };

  const handleRowClick = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  return (
    <div className={styles.userManagementContainer}>
      <div className={styles.header}>
        <h1>User Management</h1>
        <div className={styles.stats}>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(users)
                ? users.filter((u) => u.status === "active").length
                : 0}
            </span>
            <span className={styles.statLabel}>Active</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(users)
                ? users.filter((u) => u.registrationStatus === "pending").length
                : 0}
            </span>
            <span className={styles.statLabel}>Pending</span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statValue}>
              {Array.isArray(users)
                ? users.filter((u) => u.subscription === "premium").length
                : 0}
            </span>
            <span className={styles.statLabel}>Premium</span>
          </div>
        </div>
      </div>

      <div className={styles.filters}>
        <form className={styles.searchForm} onSubmit={handleSearch}>
          <div className={styles.searchInput}>
            <input
              type="text"
              placeholder="Search by username or exact email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search users"
            />
            <button type="submit">
              <BsSearch />
            </button>
          </div>
        </form>

        <div className={styles.filterControls}>
          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Status:
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                {STATUS_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Role:
              <select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                {ROLE_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Registration:
              <select
                value={registrationStatusFilter}
                onChange={(e) => setRegistrationStatusFilter(e.target.value)}
              >
                {REGISTRATION_STATUS_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className={styles.filterGroup}>
            <label>
              <BsFilter /> Subscription:
              <select
                value={subscriptionFilter}
                onChange={(e) => setSubscriptionFilter(e.target.value)}
              >
                {SUBSCRIPTION_OPTIONS.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <Button
            onClick={clearFilters}
            color="secondary"
            className={styles.clearButton}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <BsExclamationTriangle size={48} />
          <p>{error}</p>
          <Button onClick={loadUsers}>Try Again</Button>
        </div>
      ) : (
        <div className={styles.tableWrapper}>
          <table className={styles.usersTable}>
            <thead>
              <tr>
                <th
                  onClick={() => toggleSort("email")}
                  className={styles.sortableHeader}
                >
                  Email
                  {sortField === "email" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("username")}
                  className={styles.sortableHeader}
                >
                  Username
                  {sortField === "username" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th>Role</th>
                <th>Status</th>
                <th
                  onClick={() => toggleSort("registrationStatus")}
                  className={styles.sortableHeader}
                >
                  Registration Status
                  {sortField === "registrationStatus" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("subscription")}
                  className={styles.sortableHeader}
                >
                  Subscription
                  {sortField === "subscription" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSort("createdAt")}
                  className={styles.sortableHeader}
                >
                  Created At
                  {sortField === "createdAt" && (
                    <span className={styles.sortIcon}>
                      {sortDirection === "asc" ? (
                        <FaSortAmountUp />
                      ) : (
                        <FaSortAmountDown />
                      )}
                    </span>
                  )}
                </th>
                <th className={styles.actionCell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.length === 0 ? (
                <tr>
                  <td colSpan="8" className={styles.noResults}>
                    No users found matching your criteria
                  </td>
                </tr>
              ) : (
                users.map((user) => (
                  <tr
                    key={user._id}
                    className={styles.userRow}
                    onClick={() => handleRowClick(user._id)}
                  >
                    <td className={styles.userDataCell}>{user.email}</td>
                    <td className={styles.userDataCell}>{user.username}</td>
                    <td className={styles.userDataCell}>{user.role}</td>
                    <td className={styles.userDataCell}>
                      <span className={styles[`status-${user.status}`]}>
                        {user.status}
                      </span>
                    </td>
                    <td className={styles.userDataCell}>
                      {REGISTRATION_STATUS_MAP[user.registrationStatus] ||
                        user.registrationStatus}
                    </td>
                    <td className={styles.userDataCell}>
                      <span
                        className={styles[`subscription-${user.subscription}`]}
                      >
                        {user.subscription}
                      </span>
                    </td>
                    <td className={styles.userDataCell}>
                      {new Date(user.createdAt).toLocaleDateString()}
                    </td>
                    <td
                      className={styles.actionCell}
                      onClick={(e) => e.stopPropagation()} // Prevent row click when clicking on actions
                    >
                      <ReusableActionMenu
                        options={getUserActionOptions(user)}
                        icon={BsThreeDotsVertical}
                        menuHeader="User Actions"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination controls */}
      {!isLoading && !error && users.length > 0 && (
        <div className={styles.pagination}>
          <Button
            onClick={() => setPage(Math.max(1, page - 1))}
            disabled={page === 1 || isLoading}
          >
            Previous
          </Button>
          <span>
            Page {page} of {pagination.pages || 1}
          </span>
          <Button
            onClick={() => setPage(Math.min(pagination.pages || 1, page + 1))}
            disabled={page >= (pagination.pages || 1) || isLoading}
          >
            Next
          </Button>
        </div>
      )}

      <UserActionModal
        isOpen={isAdminActionModalOpen}
        onClose={handleCloseModal}
        actionType={adminActionType}
        user={selectedUser}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />

      <HardDeleteConfirmModal
        isOpen={isHardDeleteModalOpen}
        onClose={() => setIsHardDeleteModalOpen(false)}
        user={selectedUser}
        onConfirm={handleHardDelete}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default UserManagement;

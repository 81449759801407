import { useState, useEffect, useCallback, useContext } from "react";
import { unifiedContentSearch } from "api/search";
import { ToastContext } from "contexts/ToastContext";

/**
 * Custom hook for managing search functionality and content display.
 *
 * @param {Object} options - Configuration options
 * @param {number} [options.initialLimit=20] - Number of results to load initially
 * @param {string} [options.initialSort="newest"] - Initial sort order
 * @returns {Object} The search state and functions
 */
export const useSearch = ({
  initialLimit = 20,
  initialSort = "newest",
} = {}) => {
  // State declarations
  const [searchResults, setSearchResults] = useState({
    experiences: [],
    stories: [],
    metadata: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchError, setSearchError] = useState(null);

  // Get toast context if available, or use mock implementation
  const toastContext = useContext(ToastContext);
  const addToast =
    toastContext?.addToast || ((message) => console.log(`Toast: ${message}`));

  /**
   * Perform a search with the given parameters
   *
   * @param {Object} params - Search parameters
   * @param {string} params.query - The search query
   * @param {string} [params.sort="relevance"] - The sort order for results
   * @param {number} [params.limit=10] - Maximum number of results to return
   * @param {string} [params.tags] - Comma-separated list of tags to filter by
   * @param {boolean} [params.showLoading=true] - Whether to show the loading state
   * @returns {Promise<Object>} The search results
   */
  const performSearch = useCallback(
    async ({
      query,
      sort = "relevance",
      limit = 10,
      tags = null,
      showLoading = true,
    }) => {
      if (showLoading) {
        setIsLoading(true);
      }
      setSearchError(null);

      try {
        const results = await unifiedContentSearch({
          query,
          sort,
          limit,
          tags,
        });

        setSearchResults({
          experiences: results.experiences?.results || [],
          stories: results.stories?.results || [],
          metadata: results.metadata,
        });

        return results;
      } catch (error) {
        console.error("Search failed:", error);
        setSearchError("Failed to fetch search results. Please try again.");
        addToast("Failed to fetch search results. Please try again.", "error");
        throw error;
      } finally {
        if (showLoading) {
          setIsLoading(false);
        }
      }
    },
    [addToast]
  );

  /**
   * Load initial content with empty query and newest sorting
   */
  const loadInitialContent = useCallback(async () => {
    try {
      setInitialLoading(true);
      await performSearch({
        query: "",
        sort: initialSort,
        limit: initialLimit,
        showLoading: false,
      });
    } catch (error) {
      console.error("Error loading initial content:", error);
      setSearchError("Unable to load content. Please try again later.");
    } finally {
      setInitialLoading(false);
    }
  }, [performSearch, initialLimit, initialSort]);

  /**
   * Filter results by content type
   *
   * @param {string} contentType - Type of content to filter ("experiences", "stories", or "all")
   * @returns {Object} Filtered search results
   */
  const getFilteredResults = useCallback(
    (contentType) => {
      const { experiences, stories } = searchResults;

      switch (contentType) {
        case "experiences":
          return { experiences, stories: [] };
        case "stories":
          return { experiences: [], stories };
        case "all":
        default:
          return { experiences, stories };
      }
    },
    [searchResults]
  );

  /**
   * Get combined results for displaying in the "All" tab, sorted by creation date
   *
   * @returns {Array} Combined and sorted array of experiences and stories
   */
  const getCombinedResults = useCallback(() => {
    return [
      ...searchResults.experiences.map((item) => ({
        ...item,
        type: "lifeExperience",
      })),
      ...searchResults.stories.map((item) => ({ ...item, type: "story" })),
    ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [searchResults]);

  /**
   * Filter results by tag
   *
   * @param {string} tagName - Name of the tag to filter by
   * @returns {Object} Filtered search results
   */
  const filterByTag = useCallback(
    (tagName) => {
      if (!tagName) return searchResults;

      const filteredExperiences = searchResults.experiences.filter((exp) =>
        exp.tags?.some(
          (tag) => tag.name.toLowerCase() === tagName.toLowerCase()
        )
      );

      const filteredStories = searchResults.stories.filter((story) =>
        story.tags?.some(
          (tag) => tag.name.toLowerCase() === tagName.toLowerCase()
        )
      );

      return {
        experiences: filteredExperiences,
        stories: filteredStories,
      };
    },
    [searchResults]
  );

  /**
   * Check if there are any results
   *
   * @returns {boolean} True if there are results to display
   */
  const hasResults = useCallback(() => {
    return (
      searchResults.experiences.length > 0 || searchResults.stories.length > 0
    );
  }, [searchResults]);

  /**
   * Get total result count across all content types
   *
   * @returns {number} Total number of results
   */
  const getTotalCount = useCallback(() => {
    let total = 0;
    if (searchResults.experiences) {
      total += searchResults.experiences.length || 0;
    }
    if (searchResults.stories) {
      total += searchResults.stories.length || 0;
    }
    return total;
  }, [searchResults]);

  // Load initial content on mount
  useEffect(() => {
    loadInitialContent();
  }, [loadInitialContent]);

  return {
    // State
    searchResults,
    isLoading,
    initialLoading,
    searchError,

    // Actions
    performSearch,
    loadInitialContent,

    // Results helpers
    getFilteredResults,
    getCombinedResults,
    filterByTag,
    hasResults,
    getTotalCount,
  };
};

import { useState } from "react";
import styles from "./ActionModals.module.css";
import Button from "components/Button/Button";
import { useToastContext } from "contexts/ToastContext";
import {
  useFloating,
  FloatingFocusManager,
  FloatingOverlay,
  useClick,
  useRole,
  useDismiss,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";

/**
 * A reusable modal component for user actions in the admin dashboard
 * using Floating UI for better positioning and accessibility
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when closing the modal
 * @param {string} props.actionType - The type of action (Approve, Ban, Suspend, Delete, Restore)
 * @param {Object} props.user - The user object the action is being performed on
 * @param {Function} props.onSubmit - Function to call when submitting the action
 * @param {boolean} props.isSubmitting - Whether the action is currently being submitted
 */
const UserActionModal = ({
  isOpen,
  onClose,
  actionType,
  user,
  onSubmit,
  isSubmitting,
}) => {
  const [actionReason, setActionReason] = useState("");
  const [suspensionDays, setSuspensionDays] = useState(7); // Default to 7 days
  const { addToast } = useToastContext();

  // Set up floating UI
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: onClose,
  });

  const click = useClick(context);
  const role = useRole(context, { role: "dialog" });
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  // Define proper action phrases for different contexts
  const ACTION_PHRASES = {
    Approve: {
      reasonLabel: "Reason for approval",
      reasonPlaceholder: "Enter detailed reason for approving this user...",
      buttonText: "Approve",
    },
    Ban: {
      reasonLabel: "Reason for ban",
      reasonPlaceholder: "Enter detailed reason for banning this user...",
      buttonText: "Ban",
    },
    Suspend: {
      reasonLabel: "Reason for suspension",
      reasonPlaceholder: "Enter detailed reason for suspending this user...",
      buttonText: "Suspend",
    },
    Delete: {
      reasonLabel: "Reason for deletion",
      reasonPlaceholder: "Enter detailed reason for deleting this user...",
      buttonText: "Delete",
    },
    Restore: {
      reasonLabel: "Reason for restoration",
      reasonPlaceholder: "Enter detailed reason for restoring this user...",
      buttonText: "Restore",
    },
  };

  // Get the appropriate phrases for the current action type
  const actionPhrases = ACTION_PHRASES[actionType] || {
    reasonLabel: `Reason for ${
      actionType ? actionType.toLowerCase() : "this action"
    }`,
    reasonPlaceholder: `Enter detailed reason for ${
      actionType ? actionType.toLowerCase() : "this action"
    }...`,
    buttonText: actionType || "Submit",
  };

  const handleSubmit = () => {
    // Add validation to prevent restoring deleted users
    if (actionType === "Restore" && user.status === "deleted") {
      addToast("Cannot restore a deleted user.", "error");
      handleClose();
      return;
    }

    const data = {
      userId: user._id,
      reason: actionReason,
    };

    // Add suspension duration if this is a suspension action
    if (actionType === "Suspend") {
      data.durationDays = suspensionDays;
    }

    onSubmit(data);
  };

  const handleClose = () => {
    setActionReason(""); // Reset form on close
    onClose();
  };

  // Handle overlay background click manually
  const handleOverlayClick = (e) => {
    // Only close if clicking directly on the overlay (not on the modal or its children)
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen || !user) return null;

  // Determine warning message based on action type
  const getWarningMessage = () => {
    switch (actionType) {
      case "Ban":
        return "This will permanently restrict the user from accessing the platform.";
      case "Suspend":
        return `This will temporarily restrict the user from accessing the platform for ${suspensionDays} day${
          suspensionDays !== 1 ? "s" : ""
        }.`;
      case "Delete":
        return "This will remove the user account and all associated data.";
      case "Restore":
        if (user.status === "suspended") {
          return "This will restore the user from suspension, allowing them to access the platform again.";
        } else if (user.status === "banned") {
          return "This will restore the user from being banned, allowing them to access the platform again.";
        } else if (user.status === "deleted") {
          return "This will restore the deleted user account.";
        }
        return "This will restore the user account to active status.";
      default:
        return null;
    }
  };

  return (
    <FloatingPortal>
      <FloatingOverlay
        lockScroll
        className={styles.overlay}
        onClick={handleOverlayClick}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            className={styles.floatingModal}
            {...getFloatingProps()}
          >
            <div className={styles.modalHeader}>
              <h3>{actionType} User</h3>
              <button
                onClick={handleClose}
                className={styles.closeButton}
                aria-label="Close modal"
                disabled={isSubmitting}
              >
                ×
              </button>
            </div>

            <div className={styles.resourceIdentifier}>User ID: {user._id}</div>

            <div className={styles.resourceDetails}>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Username:</span>
                <span className={styles.detailValue}>{user.username}</span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Email:</span>
                <span className={styles.detailValue}>{user.email}</span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Status:</span>
                <span className={styles.detailValue}>
                  <span className={styles[`status-${user.status}`]}>
                    {user.status}
                  </span>
                </span>
              </div>
              <div className={styles.detailRow}>
                <span className={styles.detailLabel}>Role:</span>
                <span className={styles.detailValue}>{user.role}</span>
              </div>
            </div>

            <div className={styles.modalContent}>
              {actionType === "Suspend" && (
                <div className={styles.formGroup}>
                  <label htmlFor="suspensionDuration">
                    Suspension duration (days):
                  </label>
                  <input
                    id="suspensionDuration"
                    type="number"
                    min="1"
                    max="365"
                    value={suspensionDays}
                    onChange={(e) =>
                      setSuspensionDays(parseInt(e.target.value, 10))
                    }
                    disabled={isSubmitting}
                  />
                </div>
              )}

              <div className={styles.formGroup}>
                <label htmlFor="reason">{actionPhrases.reasonLabel}:</label>
                <textarea
                  id="reason"
                  placeholder={actionPhrases.reasonPlaceholder}
                  value={actionReason}
                  onChange={(e) => setActionReason(e.target.value)}
                  disabled={isSubmitting}
                />
                {getWarningMessage() && (
                  <p className={styles.warningText}>{getWarningMessage()}</p>
                )}
              </div>

              <div className={styles.modalActions}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  disabled={!actionReason || isSubmitting}
                  color={
                    actionType === "Ban" || actionType === "Delete"
                      ? "danger"
                      : "primary"
                  }
                >
                  {isSubmitting ? "Processing..." : actionPhrases.buttonText}
                </Button>
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default UserActionModal;

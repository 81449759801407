import React, { useState } from "react";
import styles from "./LifeExperienceCard.module.css";
import { formatMonthYear, getRelativeTimeFromMonthYear } from "utils/dates";
import ReusableActionMenu from "components/ReusableActionMenu/ReusableActionMenu";
import { IoEllipsisHorizontal } from "react-icons/io5";
import LifeExperienceModal from "components/LifeExperienceModal/LifeExperienceModal";
import DeleteConfirmationModal from "components/DeleteConfirmationModal/DeleteConfirmationModal";
import useLifeExperience from "./useLifeExperience";
import ReachOutModal from "components/ReachOutModal/ReachOutModal";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ReusableTooltip/ReusableTooltip";
import { GiBilledCap } from "react-icons/gi";
import { PiPlugsConnectedFill, PiHandHeart } from "react-icons/pi";
import { GiCampfire } from "react-icons/gi";
import ReportModal from "components/ReportModal/ReportModal";
import { reportLifeExperience } from "api/reports";
import { IoMdSearch } from "react-icons/io";
import cn from "classnames";
import UserInfoDialog from "components/UserInfoDialog/UserInfoDialog";
import useMediaQuery from "hooks/useMediaQuery";

const StorySection = ({ story }) => <div className={styles.story}>{story}</div>;

const TagsSection = ({ tags }) => {
  if (!tags?.length) return null;

  return (
    <div className={styles.tags}>
      {tags.map((tag) => (
        <div key={tag._id} className={styles.tag}>
          {tag.name}
        </div>
      ))}
    </div>
  );
};

const IntentsSection = ({ intents }) => {
  if (!intents) return null;

  const intentConfig = {
    venting: {
      icon: GiCampfire,
      label: "Venting",
      description: "Sharing feelings without seeking advice",
    },
    seeking_advice: {
      icon: IoMdSearch,
      label: "Seeking Advice",
      description: "Looking for guidance from others",
    },
    offering_support: {
      icon: PiHandHeart,
      label: "Offering Support",
      description: "Helping others with personal experience",
    },
    sharing_experience: {
      icon: PiPlugsConnectedFill,
      label: "Sharing Experience",
      description: "Documenting experience for others to relate",
    },
  };

  return (
    <div className={styles.intents} role="list" aria-label="Intents">
      {Object.entries(intents)
        .filter(([_, value]) => value)
        .map(([intent]) => {
          const { icon: Icon, label, description } = intentConfig[intent];
          return (
            <Tooltip key={intent}>
              <TooltipTrigger>
                <div className={styles.intent} role="listitem">
                  <Icon className={styles.intentIcon} aria-hidden="true" />
                  <span>{label}</span>
                </div>
              </TooltipTrigger>
              <TooltipContent>{description}</TooltipContent>
            </Tooltip>
          );
        })}
    </div>
  );
};

const AuthorSection = ({ experience }) => {
  const [showUserInfo, setShowUserInfo] = useState(false);

  if (!experience.author) return null;

  const shouldUsePseudonym = experience.author.username === "anonymous";

  const authorData = {
    ...experience.author,
    pseudonym: experience.authorPseudonym,
  };

  return (
    <>
      <span
        className={styles.author}
        onClick={() => setShowUserInfo(true)}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            setShowUserInfo(true);
          }
        }}
      >
        {shouldUsePseudonym ? (
          <>
            <GiBilledCap className={styles.anonymousIcon} />
            <span>{experience.authorPseudonym}</span>
          </>
        ) : (
          experience.author.username
        )}
      </span>

      <UserInfoDialog
        data={authorData}
        open={showUserInfo}
        onOpenChange={setShowUserInfo}
      />
    </>
  );
};

const MetadataSection = ({ date, isConnected, isReachedOut }) => (
  <div className={styles.metadata}>
    <Tooltip>
      <TooltipTrigger>
        <span className={styles.date}>
          experienced {getRelativeTimeFromMonthYear(date?.month, date?.year)}
        </span>
      </TooltipTrigger>
      <TooltipContent>
        {formatMonthYear(date?.month, date?.year)}
      </TooltipContent>
    </Tooltip>
    <div className={styles.statusIndicators}>
      {isReachedOut && (
        <Tooltip>
          <TooltipTrigger>
            <div className={styles.statusIndicator}>
              <PiHandHeart className={styles.statusIcon} />
            </div>
          </TooltipTrigger>
          <TooltipContent>Reached Out</TooltipContent>
        </Tooltip>
      )}
      {isConnected && (
        <Tooltip>
          <TooltipTrigger>
            <div className={styles.statusIndicator}>
              <PiPlugsConnectedFill className={styles.statusIcon} />
            </div>
          </TooltipTrigger>
          <TooltipContent>Connected</TooltipContent>
        </Tooltip>
      )}
    </div>
  </div>
);

// Add this new component to display the semantic score
const SemanticScoreSection = ({ scores }) => {
  if (!scores?.semantic) return null;

  // Convert to percentage and round to nearest whole number
  const scorePercentage = Math.round(scores.semantic * 100);

  return (
    <div className={styles.semanticScore}>
      <span className={styles.scoreLabel}>Match:</span>
      <span className={styles.scoreValue}>{scorePercentage}%</span>
    </div>
  );
};

// Update the BaseLifeExperienceCard component to include the contentLabel prop
export const BaseLifeExperienceCard = ({
  experience,
  actionMenuOptions,
  className,
  theme = "light",
  contentLabel = false,
}) => {
  const {
    story,
    tags,
    intents,
    date,
    author,
    anonymized,
    authorPseudonym,
    searchable,
    isFeatured,
    isPublic,
    isConnected,
    isReachedOut,
    scores,
  } = experience;

  const isMobile = useMediaQuery("sm");
  const isSmallWidth = useMediaQuery("xs");

  return (
    <div
      data-theme={theme}
      className={cn(styles.experienceCard, className, {
        [styles.featured]: isFeatured,
        [styles.private]: !isPublic,
        [styles.mobile]: isMobile,
        [styles.smallWidth]: isSmallWidth,
      })}
      role="article"
      aria-label={`Life experience by ${
        anonymized ? authorPseudonym : author?.username
      }`}
    >
      <div className={styles.inner}>
        {contentLabel && (
          <div className={styles.contentTypeLabel}>Life Experience</div>
        )}
        <div className={styles.headerRow}>
          <StorySection story={story} />
          {actionMenuOptions && (
            <div className={styles.actionMenu}>
              <ReusableActionMenu
                options={actionMenuOptions}
                icon={IoEllipsisHorizontal}
                theme={theme === "light" ? "light" : "dark"}
              />
            </div>
          )}
        </div>

        {/* Add semantic score display if present */}
        {scores?.semantic && <SemanticScoreSection scores={scores} />}

        <IntentsSection intents={intents} />
        <TagsSection tags={tags} />
        <div className={styles.footer}>
          <AuthorSection experience={experience} />
          <MetadataSection
            date={date}
            isConnected={isConnected}
            isReachedOut={isReachedOut}
          />
        </div>
      </div>
    </div>
  );
};

// Owner Card Component
const OwnerLifeExperienceCard = ({
  experience,
  onUpdate,
  onDelete,
  theme,
  contentLabel = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    lifeExperience,
    error,
    status,
    statusMessage,
    updateLifeExperience,
    deleteLifeExperience,
  } = useLifeExperience(experience);

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async (formData) => {
    try {
      const updatedLifeExp = await updateLifeExperience(
        experience._id,
        formData
      );
      if (updatedLifeExp) {
        onUpdate(updatedLifeExp);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating life experience:", error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      // First perform the API delete operation using the card's own hook
      await deleteLifeExperience(experience._id);

      // Then notify parent with special flag indicating API call was already made
      // This allows the parent to update its state without making another API call
      onDelete(experience._id, { apiCallCompleted: true });

      setShowDeleteConfirmation(false);
    } catch (err) {
      console.error("Error deleting life experience:", err);
    }
  };

  const actionMenuOptions = [
    { label: "Edit", onClick: handleEdit },
    { label: "Delete", onClick: handleDeleteClick },
  ];

  return (
    <>
      <BaseLifeExperienceCard
        experience={experience}
        actionMenuOptions={actionMenuOptions}
        theme={theme}
        contentLabel={contentLabel}
      />
      {isModalOpen && (
        <LifeExperienceModal
          lifeExperience={lifeExperience}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode="edit"
          onUpdate={handleUpdate}
          onDelete={handleDeleteClick}
          error={error}
          status={status}
          statusMessage={statusMessage}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteConfirm}
          itemName="life experience"
          status={status}
          statusMessage={statusMessage}
        />
      )}
    </>
  );
};

// Non-Owner Card Component
const NonOwnerLifeExperienceCard = ({
  experience,
  theme,
  contentLabel = false,
}) => {
  const [isReachOutModalOpen, setIsReachOutModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const handleReachOut = () => {
    setIsReachOutModalOpen(true);
  };

  const handleReport = () => {
    setIsReportModalOpen(true);
  };

  const handleSubmitReport = async (reportData) => {
    return reportLifeExperience({
      targetId: experience._id,
      ...reportData,
    });
  };

  const actionMenuOptions = [
    { label: "Reach Out", onClick: handleReachOut },
    { label: "Report", onClick: handleReport },
  ];

  return (
    <>
      <BaseLifeExperienceCard
        experience={experience}
        actionMenuOptions={actionMenuOptions}
        theme={theme}
        contentLabel={contentLabel}
      />
      <ReachOutModal
        isOpen={isReachOutModalOpen}
        onClose={() => setIsReachOutModalOpen(false)}
        type="LifeExperience"
        resource={experience}
      />
      {isReportModalOpen && (
        <ReportModal
          isOpen={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          onSubmit={handleSubmitReport}
          targetType="LifeExperience"
          targetData={experience}
          targetId={experience._id}
        />
      )}
    </>
  );
};

// Main LifeExperienceCard Component
const LifeExperienceCard = ({
  lifeExpData,
  userData,
  onUpdate,
  onDelete,
  onReachOut,
  theme = "transparent",
  contentLabel = false,
}) => {
  // To create reusability across the site (to be used for simple viewing)
  if (!userData || !onUpdate || !onDelete)
    return (
      <NonOwnerLifeExperienceCard
        experience={lifeExpData}
        onReachOut={onReachOut}
        theme={theme}
        contentLabel={contentLabel}
      />
    );

  const isOwner = lifeExpData.author._id === userData._id;

  return isOwner ? (
    <OwnerLifeExperienceCard
      experience={lifeExpData}
      onUpdate={onUpdate}
      onDelete={onDelete}
      theme={theme}
      contentLabel={contentLabel}
    />
  ) : (
    <NonOwnerLifeExperienceCard
      experience={lifeExpData}
      theme={theme}
      contentLabel={contentLabel}
    />
  );
};

export default LifeExperienceCard;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "hooks/useMediaQuery";
import styles from "./FindOthers.module.css";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import LifeExperienceCard from "components/LifeExperienceCard/LifeExperienceCard";
import StoryCard from "components/StoryCard/StoryCard";
import { RiQuillPenLine } from "react-icons/ri";
import { MdOutlineTimeline } from "react-icons/md";
import { FaLayerGroup, FaUserFriends } from "react-icons/fa";
import cn from "classnames";
import { useSearch } from "hooks/useSearch";

// Constants for tab names
const TABS = {
  ALL: "all",
  LIFE_EXPERIENCES: "experiences",
  STORIES: "stories",
};

function FindOthers() {
  const [input, setInput] = useState("");
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const textareaRef = useRef(null);
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("sm");
  const isExtraSmall = useMediaQuery("xs");

  // Use our generic search hook for search functionality
  const {
    searchResults,
    isLoading,
    initialLoading,
    searchError,
    performSearch,
    loadInitialContent,
    getFilteredResults,
    getCombinedResults,
    hasResults,
  } = useSearch({ initialLimit: 20 });

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  // Handle initial query from navigation state
  useEffect(() => {
    const handleInitialSearch = async () => {
      const initialQuery = location.state?.initialQuery;
      const shouldAutoSearch = location.state?.autoSearch;

      if (initialQuery && shouldAutoSearch) {
        setInput(initialQuery);
        performSearch({ query: initialQuery });
        navigate(".", { replace: true, state: {} });
      }
    };

    handleInitialSearch();
  }, [location.state, navigate, performSearch]);

  // Handle search change with debounce
  const handleSearchChange = (event) => {
    setInput(event.target.value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const handleSearchSubmit = () => {
    if (!input.trim()) return;
    performSearch({ query: input });
  };

  const handleConnectWithSomeone = () => {
    navigate("/lobby", {
      state: { story: input },
    });
  };

  const clearSearch = () => {
    setInput("");
    loadInitialContent();
  };

  const renderTabButton = (tabName, label, icon) => {
    const Icon = icon;
    return (
      <button
        className={cn(styles.tabButton, {
          [styles.activeTab]: activeTab === tabName,
          [styles.mobileTabButton]: isMobile,
        })}
        onClick={() => setActiveTab(tabName)}
        aria-selected={activeTab === tabName}
        role="tab"
      >
        <Icon className={styles.tabIcon} />
        <span>{label}</span>
      </button>
    );
  };

  const renderContent = () => {
    if (isLoading || initialLoading) {
      return (
        <div className={styles.loadingContainer}>
          <LoadingSpinner size="medium" />
        </div>
      );
    }

    if (searchError) {
      return <p className={styles.errorMessage}>{searchError}</p>;
    }

    if (!hasResults() && input.trim()) {
      return (
        <p className={styles.emptyMessage}>
          <FaLayerGroup size={32} />
          No results found for your search
        </p>
      );
    }

    if (!hasResults()) {
      return (
        <p className={styles.emptyMessage}>
          <FaLayerGroup size={32} />
          No content found
        </p>
      );
    }

    // For the ALL tab, use our combined and chronologically sorted results
    if (activeTab === TABS.ALL) {
      const combinedResults = getCombinedResults();

      return (
        <div className={styles.contentList}>
          {combinedResults.map((item) =>
            item.type === "lifeExperience" ? (
              <LifeExperienceCard
                key={`experience-${item._id}`}
                lifeExpData={item}
                contentLabel={true}
              />
            ) : (
              <StoryCard
                key={`story-${item._id}`}
                story={item}
                contentLabel={true}
              />
            )
          )}
        </div>
      );
    }

    // For individual tabs, use filtered results
    const filteredResults = getFilteredResults(activeTab);

    return (
      <div className={styles.contentList}>
        {activeTab === TABS.LIFE_EXPERIENCES &&
          filteredResults.experiences.length > 0 && (
            <div className={styles.resultSection}>
              {filteredResults.experiences.map((experience) => (
                <LifeExperienceCard
                  key={`experience-${experience._id}`}
                  lifeExpData={experience}
                  contentLabel={true}
                />
              ))}
            </div>
          )}

        {activeTab === TABS.STORIES && filteredResults.stories.length > 0 && (
          <div className={styles.resultSection}>
            {filteredResults.stories.map((story) => (
              <StoryCard
                key={`story-${story._id}`}
                story={story}
                contentLabel={true}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.containerMobile]: isMobile,
      })}
    >
      <div className={styles.contentWrapper}>
        <h1 className={styles.pageTitle}>Find Someone Like You</h1>

        {/* Search bar at the top */}
        <div className={styles.searchSection}>
          <div className={styles.searchBarContainer}>
            <textarea
              value={input}
              onChange={handleSearchChange}
              placeholder="What are you going through?"
              className={cn(styles.searchInput, {
                [styles.extraSmall]: isExtraSmall,
              })}
              rows={1}
              ref={textareaRef}
            />
            <div className={styles.searchActions}>
              <button
                className={styles.searchButton}
                onClick={handleSearchSubmit}
                disabled={!input.trim()}
              >
                Search
              </button>
              {input.trim() && (
                <button className={styles.clearButton} onClick={clearSearch}>
                  Clear
                </button>
              )}
            </div>
          </div>

          {/* Talk to someone action button */}
          {input.trim() && (
            <button
              className={styles.connectButton}
              onClick={handleConnectWithSomeone}
            >
              <FaUserFriends />
              <span>Talk to Someone Now</span>
            </button>
          )}
        </div>

        {/* Content tabs */}
        {hasResults() && (
          <div className={styles.tabsContainer}>
            <div
              className={cn(styles.tabButtons, {
                [styles.mobileTabButtons]: isMobile,
              })}
            >
              {renderTabButton(TABS.ALL, "All", FaLayerGroup)}
              {renderTabButton(
                TABS.LIFE_EXPERIENCES,
                "Life Experiences",
                MdOutlineTimeline
              )}
              {renderTabButton(TABS.STORIES, "Stories", RiQuillPenLine)}
            </div>
            <div className={styles.tabContent}>{renderContent()}</div>
          </div>
        )}

        {/* Show intro text when no results are showing */}
        {!input.trim() && initialLoading && (
          <div className={styles.loadingContainer}>
            <LoadingSpinner size="medium" />
          </div>
        )}

        {/* Show a loading or empty state when needed */}
        {!input.trim() && !hasResults() && !initialLoading && (
          <div className={styles.introContainer}>
            <p className={styles.introText}>
              Search for experiences and stories from people who are going
              through something similar to you. You can also connect with
              someone directly to talk in real-time.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default FindOthers;
